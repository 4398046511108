// CSS Imports
import "@fontsource-variable/figtree";
import '../css/index.css';

// NPM Imports
import Alpine from 'alpinejs';
import ui from '@alpinejs/ui';
import focus from '@alpinejs/focus';
import collapse from '@alpinejs/collapse';
import persist from '@alpinejs/persist';

window.Alpine = Alpine;
Alpine.plugin(ui);
Alpine.plugin(focus);
Alpine.plugin(collapse);
Alpine.plugin(persist);
Alpine.start();

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}
